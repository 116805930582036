<template>
	<div v-if="new Array('Admin','SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
			<v-card-title>Gerer les rooms</v-card-title>

            <v-card-text class="my-3">
                <v-data-table :headers="headers" :items="rooms" item-key="name" :search="search">
                    <template v-slot:top>
                        <v-text-field v-model="search" label="Search" class="mx-4" />
                    </template>
                    <template v-slot:item.lockLevel="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon :color="item.lockLevel.joining ? 'success' : 'error'" @click="toggleLockLevel(item,'joining')">
                                    <v-icon>{{ item.lockLevel.joining ? 'mdi-account-outline' : 'mdi-account-lock-outline' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Autoriser ou non les nouveaux utilisateurs à rejoindre la room</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon :color="item.lockLevel.editing ? 'success' : 'error'" @click="toggleLockLevel(item,'editing')">
                                    <v-icon>{{ item.lockLevel.editing ? 'mdi-pencil-outline' : 'mdi-pencil-lock-outline' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Autoriser ou non les utilisateurs à participer à la room</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon :color="item.lockLevel.access ? 'success' : 'error'" @click="toggleLockLevel(item,'access')">
                                    <v-icon>{{ item.lockLevel.access ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Autoriser ou non les utilisateurs à avoir accès à la room</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="primary" @click="saveAsTemplate(item)">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="success" @click="generateNewRoomCode(item)">
                            <v-icon>mdi-lock-reset</v-icon>
                        </v-btn>
                        <v-btn icon color="secondary" @click="openCreationModal(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="warning" @click="archiveRoom(item)">
                            <v-icon>mdi-archive-lock-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click="deleteRoom(item)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
                <span class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab @click="openCreationModal(null)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </span>
            </v-card-text>
        </v-card>
        <CreationModal ref="creationModal" :profile="profile" :data="creationModalData" title="Creer une Room" @save="handleSaveRoom" @loadTemplate="handleLoadTemplate"></CreationModal>
        <v-dialog v-model="templateLoaderIsOpen" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline" v-if="templateIsLoading">Chargement de votre template...</v-card-title>
                <v-card-title class="headline" v-else>Chargement terminé</v-card-title>
                <v-card-text>
                    <v-progress-linear v-if="templateIsLoading" color="primary" indeterminate></v-progress-linear>
                    <span class="d-flex align-center justify-center mt-5" v-else>
                        <v-btn outlined color="primary" @click="templateLoaderIsOpen = false">Ok</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="archiveLoaderIsOpen" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline" v-if="archiveIsLoading">Extraction de l'archive...</v-card-title>
                <v-card-title class="headline" v-else>Extraction terminé</v-card-title>
                <v-card-text>
                    <v-progress-linear v-if="archiveIsLoading" color="primary" indeterminate></v-progress-linear>
                    <span class="d-flex align-center justify-center mt-5" v-else>
                        <v-btn outlined color="primary" @click="archiveLoaderIsOpen = false">Ok</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>
	</div>
</template>

<script>
    import downloader from "@/assets/functions/downloader"  
    import logger from "@/assets/functions/logger"
    import archiveMaker from "@/assets/functions/archiveMaker.js"
    import templateManager from "@/assets/functions/templateManager"

	import Swal from 'sweetalert2/dist/sweetalert2.js'
	import CreationModal from "@/components/Rooms/CreationModal.vue"

    import Licence from "@/classes/Licence"
    import Room from "@/classes/Room"
    import Profile from "@/classes/Profile"

	export default {
		name: "Rooms",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		components: {
            CreationModal,
		},
		data() {
			return {
                unsub: [],
                search: "",
                rooms: [],
                licencesDict: {},
                templateLoaderIsOpen: false,
                templateIsLoading: false,
                archiveLoaderIsOpen: false,
                archiveIsLoading: false,
				headers: [
					{
						text: "Verouillage",
						value: "lockLevel",
						sortable: false,
                        align: "center",
					},
					{
						text: "Nom",
						value: "name",
						sortable: true,
					},
					{
						text: "Licence",
						value: "licence.name",
						sortable: true,
					},
					{
						text: "Code d'activation",
						value: "code",
						sortable: true,
                        align: "center",
					},
					{
						text: "Users",
						value: "userAmount",
						sortable: true,
                        align: "center",
					},
					{
						text: "Action",
						value: "action",
						sortable: false,
                        align: "center",
					},
				],
                creationModalData: {
                    id:null,
                    name: "",
                    licence: {},
                    code: "",
                    userAmount: "",
                    lockLevel: "",
                },
                creationModalTitle: "Creer une Room",
			}
		},
		created() {
            this.unsub.push(Licence.listenAll( licences => {
                this.licencesDict = {}
                licences.forEach(licence => {
                    this.licencesDict[licence.id] = licence
                })

                if(this.profile.role == "SuperAdmin") {
                    this.unsub.push(Room.listenAll(rooms => {
                        let tmp_rooms = rooms
                        tmp_rooms.forEach(room => {
                            room.licence = this.licencesDict[room.licence]
                        })
                        this.rooms = tmp_rooms
                    }))
                }else if(this.profile.role == "Admin" && this.profile.licence){
                    this.unsub.push(Room.listenByLicence(this.profile.licence, rooms => {
                        let tmp_rooms = rooms
                        tmp_rooms.forEach(room => {
                            room.licence = this.licencesDict[room.licence]
                        })
                        this.rooms = tmp_rooms
                    }))
                }else{
                    this.rooms = []
                }
            }))
		},
		methods: {
			openCreationModal(room){
                if(!room){
                    this.creationModalTitle = "Creer une room"
                    this.creationModalData.id = null
                    this.creationModalData.name = ""
                    this.creationModalData.licence = ""
                    this.creationModalData.code = ""
                    this.creationModalData.userAmount = 0
                    this.creationModalData.lockLevel = {joining: false, editing: false, access: false}
                }else{
                    this.creationModalTitle = "Modifier une room"
                    this.creationModalData.id = room.id
                    this.creationModalData.name = room.name
                    this.creationModalData.licence = room.licence.id
                    this.creationModalData.code = room.code
                    this.creationModalData.userAmount = room.userAmount
                    this.creationModalData.lockLevel = room.lockLevel
                }
				this.$refs.creationModal.open()
			},
            handleSaveRoom(data){
                if(data.id){
                    this.updateRoom(data)
                }else{
                    this.addRoom(data)
                }
            },
            async handleLoadTemplate(configData){
                this.templateIsLoading = true
                this.templateLoaderIsOpen = true

                await templateManager.createRoomFromTemplate(configData.data.name, configData.data.licence, configData.startDate, configData.template)
                
                this.templateIsLoading = false
            },
			addRoom(data) {
                let room = new Room(null, data.name, data.licence)
                room.save()
                .then(() => {
                    let tmp_licence = new Licence(this.licencesDict[data.licence].id, this.licencesDict[data.licence].name, this.licencesDict[data.licence].userAmount, this.licencesDict[data.licence].maxUserAmount)
                    tmp_licence.save()
                    .then(() => {
                        logger.log(this.profile.id, "ROOM ADD", this.profile.email + " a creer la room " + data.name)
                    })
                })
			},
            updateRoom(data){
                let room = new Room(data.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
                room.save()
                .then(() => {
                    logger.log(this.profile.id, "ROOM UPDATED", this.profile.email + " a modifié la room " + data.name)
                })
            },
            generateNewRoomCode(room){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if (result.isConfirmed) {
                        room.generateNewCode()
                        .then(() => {
                            logger.log(this.profile.id, "ROOM CODE", this.profile.email + " a rechargé le code de la room " + room.name)
                        })
					}
				})
                
            },
            toggleLockLevel(room, lockLevelType){
                room.toggleLockLevel(lockLevelType)
            },
			deleteRoom(room) {
				Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if (result.isConfirmed) {
                        let tmp_licence = new Licence(this.licencesDict[room.licence.id].id, this.licencesDict[room.licence.id].name, this.licencesDict[room.licence.id].userAmount, this.licencesDict[room.licence.id].maxUserAmount)
                        tmp_licence.save()
                        .then(() => {
                            room.delete()
                            logger.log(this.profile.id,"LICENCE DELETE",this.profile.email + " a supprimé la room " + room.name)
                        })
					}
				})
			},

            async archiveRoom(room){
                this.archiveIsLoading = true
                this.archiveLoaderIsOpen = true
                
                await archiveMaker.archiveRoom(room.id)
                
                this.archiveIsLoading = false
            },

            async saveAsTemplate(room){
                let template = await templateManager.saveTemplate(this.profile.id, room.id)
                const templateName = room.name.trim().replace(' ', '_') + '.valy'
                downloader.downloadUncodedUri(template, templateName)
            },
		},
		destroyed() {
            this.unsub.forEach((unsub) => {
                unsub()
            })
        },
	}
</script>
