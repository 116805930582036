
import CryptoJS from "crypto-js"
let CryptoKey = "9a8cda40689d57f401ac182f034573d20360db3c62bb3338f83e8c11bd83bdd18440d1b066a0387d0a466bb945c8b6741d6d11dffa154a48b5c0fae755477b86"

import Room from "@/classes/Room"
import Form from "@/classes/Form"
import SlideForm from "@/classes/SlideForm"
import Contact from "@/classes/Contact"
import Mail from "@/classes/Mail"
import Phone from "@/classes/Phone"
import File from "@/classes/File"
import Event from "@/classes/Event"
import Task from "@/classes/Task"

let templateManager = {
    async getTemplateData(creator, roomId){
        let data = {
            creator: creator,
            startingDate: null,
            forms: [],
            slideForms: [],
            contacts: [],
            mails: [],
            phones: [],
            files: [],
            events: [],
            tasks: [],
        }

        let tmp_forms = await Form.getByRoom(roomId)
        for(let form of tmp_forms){
            data.forms.push(form)
        }

        let tmp_slideForms = await SlideForm.getByRoom(roomId)
        for(let slideForm of tmp_slideForms){
            data.slideForms.push(slideForm)
        }

        let tmp_contacts = await Contact.getByRoom(roomId)
        for(let contact of tmp_contacts){
            data.contacts.push(contact)
        }

        let tmp_mails = await Mail.getByRoom(roomId)
        for(let mail of tmp_mails){
            data.mails.push(mail)
            if(data.startingDate == null || mail.date < data.startingDate){
                data.startingDate = mail.date
            }
            if(typeof mail.uniqueAnswer == "number" && (data.startingDate == null || mail.uniqueAnswer < data.startingDate)){
                data.startingDate = mail.uniqueAnswer
            }
        }

        let tmp_phones = await Phone.getByRoom(roomId)
        for(let phone of tmp_phones){
            data.phones.push(phone)
            if(data.startingDate == null || phone.date < data.startingDate){
                data.startingDate = phone.date
            }
        }

        let tmp_files = await File.getByRoom(roomId)
        for(let file of tmp_files){
            data.files.push(file)
            if(data.startingDate == null || file.date < data.startingDate){
                data.startingDate = file.date
            }
        }

        let tmp_events = await Event.getByRoomAndByType(roomId, "global")
        for(let event of tmp_events){
            data.events.push(event)
            if(data.startingDate == null || event.start < data.startingDate){
                data.startingDate = event.start
            }
        }

        let tmp_tasks = await Task.getByRoomAndByType(roomId, "global")
        for(let task of tmp_tasks){
            data.tasks.push(task)
            if(data.startingDate == null || task.date < data.startingDate){
                data.startingDate = task.date
            }
        }

        return data
    },

    async createRoomFromTemplate(name, licence, startDate, template){

        let refTable = {
            forms: {},
            slideForms: {},
            contacts: {},
        }

        let offset = this.computeDaysOffset(startDate, template.startingDate)

        let new_room = new Room(null, name, licence)
        await new_room.save()
        
        let new_forms = []
        for(let form of template.forms){
            let new_form = new Form(null, new_room.id, form.title, form.questions, form.rdmOrder)
            await new_form.save()
            new_forms.push(new_form)
            refTable.forms[form.id] = new_form.id
        }

        let new_slideForms = []
        for(let slideForm of template.slideForms){
            let newDate = new Date(new Date(slideForm.endDate).getTime() + offset).getTime()
            let new_slideForm = new SlideForm(null, slideForm.name, new_room.id, slideForm.correctionType, slideForm.editableResponses, newDate, slideForm.pointsConvertion, slideForm.slides, slideForm.showCorrection)
            new_slideForm.isNewForm = true
            new_slideForm.id = await SlideForm.getANewId()
            await new_slideForm.save()
            new_slideForms.push(new_slideForm)
            refTable.slideForms[slideForm.id] = new_slideForm.id
        }
        
        let new_contacts = []
        for(let contact of template.contacts){
            let new_contact = new Contact(null, new_room.id, contact.firstname, contact.lastname, contact.domain, contact.description)
            await new_contact.save()
            new_contacts.push(new_contact)
            refTable.contacts[contact.id] = new_contact.id
        }
        
        let new_mails = []
        for(let mail of template.mails){
            /**
             * à corrigé
             */
            if (mail.receiver === 'all') {
                let newDate = new Date(new Date(mail.date).getTime() + offset).getTime()
    
                let newSender = mail.sender == null ? null : refTable.contacts[mail.sender]
                let newForm = mail.form
                if(mail.responseType == "form"){
                  newForm = mail.form == null ? null : refTable.forms[mail.form]
                }else if(mail.responseType == "slideForm"){
                  newForm = mail.form == null ? null : refTable.slideForms[mail.form]
                }

                let newUniqueAnswer = mail.uniqueAnswer
                if(typeof mail.uniqueAnswer == "number"){
                  newUniqueAnswer = new Date(new Date(mail.uniqueAnswer).getTime() + offset).getTime()
                }
                // new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                let new_mail = new Mail(null, new_room.id, newDate, mail.responseType, newSender, mail.receiver, mail.urgent, mail.subject, mail.message, mail.attachments, newForm, mail.showCorrection, newUniqueAnswer)
                await new_mail.save()
                new_mails.push(new_mail)
            }
        }
        
        let new_phones = []
        for(let phone of template.phones){
            let newDate = new Date(new Date(phone.date).getTime() + offset).getTime()

            let newSender = phone.sender == null ? null : refTable.contacts[phone.sender]

            let new_phone = new Phone(null, new_room.id, newDate, phone.responseType, newSender, phone.subject, phone.file)
            await new_phone.save()
            new_phones.push(new_phone)
        }
        
        let new_files = []
        for(let file of template.files){
            let newDate = new Date(new Date(file.date).getTime() + offset).getTime()

            let new_file = new File(null, new_room.id, newDate, file.type, file.path, file.name, file.ref)
            await new_file.save()
            new_files.push(new_file)
        }
        
        let new_events = []
        for(let event of template.events){
            let newStart = new Date(new Date(event.start).getTime() + offset).getTime()
            let newEnd = new Date(new Date(event.end).getTime() + offset).getTime()

            let new_event = new Event(null, new_room.id, "global", event.name, newStart, newEnd, null, event.link, event.metadata)
            await new_event.save()
            new_events.push(new_event)
        }
        
        let new_tasks = []
        for(let task of template.tasks){
            let newDate = new Date(new Date(task.date).getTime() + offset).getTime()

            let new_task = new Task(null, new_room.id, "global", newDate, task.name, task.desc, {}, null)
            await new_task.save()
            new_tasks.push(new_task)
        }
        
        return
    },

    computeDaysOffset(startingDate, templateDate){
        let templateTimestamp = new Date(templateDate).getTime()
        let startingTimestamp = new Date(startingDate).getTime()

        let timeOffset = Math.abs(startingTimestamp - templateTimestamp);
        let daysOffset = Math.ceil(timeOffset / (1000 * 60 * 60 * 24))

        return daysOffset * 24 * 60 * 60 * 1000
    },

    encodeTemplate(data){
        return CryptoJS.AES.encrypt(JSON.stringify(data), CryptoKey).toString()
    },

    decodeTemplate(data){
        return JSON.parse(CryptoJS.AES.decrypt(data, CryptoKey).toString(CryptoJS.enc.Utf8))
    },

    async saveTemplate(creator, roomId){
        let data = await this.getTemplateData(creator, roomId)
        return this.encodeTemplate(data)
    }
}

export default templateManager