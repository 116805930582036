<template>
	<v-dialog v-model="displayed" max-width="600px" persistent>
		<v-card>
			<v-card-title>
				<span class="text-h5" v-text="title"></span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Nom de la room" required v-model="data.name" />
						</v-col>
						<v-col cols="12" v-if="profile.role == 'SuperAdmin' && data.id == null">
							<v-select :items="licenceList" item-value="id" item-text="name" v-model="data.licence" label="Licence"></v-select>
						</v-col>
						<v-col cols="12" v-if="new Array('Admin','SuperAdmin').includes(profile.role) && data.id == null">
							<v-switch label="Charger un fichier template (.valy)" v-model="loadTemplate"></v-switch>
						</v-col>
						<v-col cols="12" v-if="new Array('Admin','SuperAdmin').includes(profile.role) && loadTemplate && data.id == null">
                            <span>
                                <v-file-input accept=".valy" chips truncate-length="30" label="Template" v-model="templateFile"></v-file-input>
                            </span>
						</v-col>
						<v-col cols="12" v-if="new Array('Admin','SuperAdmin').includes(profile.role) && loadTemplate && data.id == null">
                            <span>
                                <v-text-field type="date" label="Date de lancement" v-model="startDate" />
                            </span>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
				<v-btn color="blue darken-1" text @click="save"> sauvegarder </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import logger from "@/assets/functions/logger"
    import templateManager from "@/assets/functions/templateManager"

	import Swal from 'sweetalert2/dist/sweetalert2.js'

    import Licence from "@/classes/Licence"

	export default {
		props: ["data", "title", "profile"],
		data() {
			return {
                unsub: [],
				displayed: false,
                licenceList: [],
                loadTemplate: false,
                templateFile: null,
                startDate: new Date().toISOString().slice(0, 10)
			} 
		},
        created() {
            this.unsub.push(Licence.listenAll( licences => {
                this.licenceList = licences
            }))
        },
        destroyed() {
            this.unsub.forEach((unsub) => {
                unsub()
            })
        },
		methods: {
			open() {
				this.displayed = true
			},
			close() {
				this.displayed = false
			},
			save() {
                if(this.profile.role != "SuperAdmin") {
                    this.data.licence = this.profile.licence
                }

                if (this.data.name && this.data.licence && this.data.name.trim() != "" && this.data.licence.trim() != "" ) {
                    if(this.loadTemplate && this.templateFile){
                        let reader = new FileReader()
                        reader.onload = (e) => {
                            let data = e.target.result
                            this.$emit("loadTemplate", {"data" : this.data,"startDate" : this.startDate , "template" : templateManager.decodeTemplate(data)})
                            this.close()
                        }
                        reader.readAsText(this.templateFile)
                    }else{
                        this.$emit("save", this.data)
                        this.close()
                    }
                    
                } else {
                    Swal.fire({
                        title: "Erreur",
                        text: "Les données entrées sont vides ou incorrectes",
                        icon: "error",
                        confirmButtonText: "OK",
                    })
                }
                
			},
		},
	}
</script>
