import { getStorage, ref, getBlob } from "firebase/storage"
let storage = getStorage()

import * as XLSX from "xlsx/xlsx.mjs"
import JSZip from "jszip"
import downloader from "@/assets/functions/downloader"
import CryptoJS from "crypto-js"

let CryptoKey = "9a8cda40689d57f401ac182f034573d20360db3c62bb3338f83e8c11bd83bdd18440d1b066a0387d0a466bb945c8b6741d6d11dffa154a48b5c0fae755477b86"

import Room from "@/classes/Room"
import Profile from "@/classes/Profile"
import Contact from "@/classes/Contact"
import Group from "@/classes/Group"
import Form from "@/classes/Form"
import Mail from "@/classes/Mail"
import MailResponse from "@/classes/MailResponse"
import Phone from "@/classes/Phone"
import PhoneResponse from "@/classes/PhoneResponse"
import Event from "@/classes/Event"
import Task from "@/classes/Task"
import File from "@/classes/File"
import Note from "@/classes/Note"
import Log from "@/classes/Log"

let archiveMaker = {
    async getDataFromRoom(roomId){
        let data = {
            room: {},            
            profiles : {},
            contacts : {},
            groups : {},
            forms : {},
            mails : {},
            mailResponses : {},
            phones : {},
            phoneResponses : {},
            events : {},
            tasks : {},
            files : {},
            notes : {},
            logs : {},
        }
        //Get Room
        let room = await Room.getById(roomId)
        data.room = room

        //Get Profiles
        let tmp_profiles = await Profile.getByRoomAndByRole(roomId, "User")
        tmp_profiles.forEach(async profile => {
            //Get Logs
            let tmp_logs = await Log.getByProfileId(profile.id)
            tmp_logs.forEach(log => {
                data.logs[log.id] = log.toReadableData()
            })
            data.profiles[profile.id] = profile
        })

        //Get Contacts
        let tmp_contacts = await Contact.getByRoom(roomId)
        tmp_contacts.forEach(contact => {
            data.contacts[contact.id] = contact
        })

        //Get Groups
        let tmp_groups = await Group.getByRoom(roomId)
        tmp_groups.forEach(group => {
            data.groups[group.id] = group
        })

        //Get Forms
        let tmp_forms = await Form.getByRoom(roomId)
        tmp_forms.forEach(form => {
            let tmp_form = form
            tmp_form.decode()
            data.forms[form.id] = tmp_form
        })

        //Get Mails
        let tmp_mails = await Mail.getByRoom(roomId)
        tmp_mails.forEach(async mail => {
            //Get MailResponses
            let tmp_mailResponses = await MailResponse.getByMail(mail.id)
            tmp_mailResponses.forEach(mailResponse => {
                data.mailResponses[mailResponse.id] = mailResponse
            })
            data.mails[mail.id] = mail
        })

        //Get Phones
        let tmp_phones = await Phone.getByRoom(roomId)
        tmp_phones.forEach(async phone => {
            //Get PhoneResponses
            let tmp_phoneResponses = await PhoneResponse.getByPhone(phone.id)
            tmp_phoneResponses.forEach(phoneResponse => {
                data.phoneResponses[phoneResponse.id] = phoneResponse
            })
            data.phones[phone.id] = phone
        })

        //Get GlobalEvents
        let tmp_events = await Event.getByRoomAndByType(roomId, "global")
        tmp_events.forEach(event => {
            data.events[event.id] = event
        })

        //Get Tasks
        let tmp_tasks = await Task.getByRoomAndByType(roomId, "global")
        tmp_tasks.forEach(task => {
            data.tasks[task.id] = task
        })

        //Get Files
        let tmp_files = await File.getByRoom(roomId)
        tmp_files.forEach(file => {
            data.files[file.id] = file
        })
        
        //Get Notes
        let tmp_notes = await Note.getByRoom(roomId)
        tmp_notes.forEach(note => {
            data.notes[note.id] = note
        })
        
        return data
    },

    getResponseData(data){
        let responses = {
            mails : {},
            phones : {},
            responses: {}
        }
        
        for(let mailId in data.mails){
            if(data.mails[mailId].responseType != "none"){
                responses.mails[mailId] = {}
                for(let profile of Object.values(data.profiles)){
                    responses.mails[mailId][profile.id] = {}
                }
            }
        }

        for(let responseId in data.mailResponses){
            let mail = data.mails[data.mailResponses[responseId].mail]
            if(responses.mails[mail.id] != undefined){
                if(mail.responseType == "text"){
                    responses.mails[mail.id][data.mailResponses[responseId].sender] = {
                        type : "text",
                        response : data.mailResponses[responseId].message,
                        attachments : null,
                        date : data.mailResponses[responseId].date,
                        id : data.mailResponses[responseId].id,
                    }
                }else if(mail.responseType == "file"){
                    responses.mails[mail.id][data.mailResponses[responseId].sender] = {
                        type : "file",
                        response : data.mailResponses[responseId].message,
                        attachments : data.mailResponses[responseId].attachments,
                        date : data.mailResponses[responseId].date,
                        id : data.mailResponses[responseId].id,
                    }
                }else if(mail.responseType == "form"){
                    responses.mails[mail.id][data.mailResponses[responseId].sender] = {
                        type : "form",
                        response : data.mailResponses[responseId].form,
                        attachments : null,
                        date : data.mailResponses[responseId].date,
                        id : data.mailResponses[responseId].id,
                    }
                }
            }
        }

        for(let phoneId in data.phones){
            if(data.phones[phoneId].responseType != "none"){
                responses.phones[phoneId] = {}
                for(let profile of Object.values(data.profiles)){
                    responses.phones[phoneId][profile.id] = {}
                }
            }
        }

        for(let responseId in data.phoneResponses){
            let phone = data.phones[data.phoneResponses[responseId].message]
            if(responses.phones[phone.id] != undefined){
                if(phone.responseType == "text"){
                    responses.phones[phone.id][data.phoneResponses[responseId].sender] = {
                        type : "text",
                        response : data.phoneResponses[responseId].text,
                        attachments : null,
                        date : data.phoneResponses[responseId].date,
                        id : data.phoneResponses[responseId].id,
                    }
                }else if(phone.responseType == "file"){
                    responses.phones[phone.id][data.phoneResponses[responseId].sender] = {
                        type : "file",
                        response : data.phoneResponses[responseId].text,
                        attachments : data.phoneResponses[responseId].attachments,
                        date : data.phoneResponses[responseId].date,
                        id : data.phoneResponses[responseId].id,
                    }
                }
            }
        }

        // sort by profiles
        for(let profileId in data.profiles){
            responses.responses[profileId] = {
                mails: {},
                phones: {}
            }

            // get each mail response by user
            let mailResponsesOfUser = Object.values(data.mailResponses).filter(response=>response.sender===profileId)
            for(let response of mailResponsesOfUser){
                responses.responses[profileId].mails[response.id] = {
                    type: 'text',
                    response: data.mailResponses[response.id].message,
                    attachments: null,
                    date: data.mailResponses[response.id].date,
                    id: data.mailResponses[response.id].id,
                    subject: data.mails[response.mail].subject,
                    originType: 'mail'
                }
                
                // get responseType of the origin mail
                let responseType = data.mails[response.mail].responseType
                if(responseType == 'file'){
                    responses.responses[profileId].mails[response.id].type = 'file'
                    responses.responses[profileId].mails[response.id].attachments = data.mailResponses[response.id].attachments
                }else if(responseType == 'form'){
                    responses.responses[profileId].mails[response.id].type = 'form'
                    responses.responses[profileId].mails[response.id].response = data.mailResponses[response.id].form
                }
            }

            // get each phone response by user
            let phoneResponsesOfUser = Object.values(data.phoneResponses).filter(response=>response.sender===profileId)
            for(let response of phoneResponsesOfUser){
                responses.responses[profileId].phones[response.id] = {
                    type: 'text',
                    response : data.phoneResponses[response.id].text,
                    attachments: null,
                    date: data.phoneResponses[response.id].date,
                    id: data.phoneResponses[response.id].id,
                    subject: data.phones[response.message].subject,
                    originType: 'phone'
                }
                
                // get responseType of the origin phone
                let responseType = data.phones[response.message].responseType
                if(responseType == 'file'){
                    responses.responses[profileId].phones[response.id].type = 'file'
                    responses.responses[profileId].phones[response.id].attachments = data.phoneResponses[response.id].attachments
                }
            }
        }

        return responses
    },

    buildTimeline(data){
        let timelineBook = XLSX.utils.book_new()
        
        let milestones = []

        let profileMilestones = {}
        for(let profileId in data.profiles){
            profileMilestones[profileId] = []
        }

        let tmp_mails = Object.values(data.mails)
        for(let mail of tmp_mails){
            if(Object.keys(data.contacts).includes(mail.sender)){
                milestones.push({
                    date : mail.date,
                    type : "mail",
                    id : mail.id,
                    name : mail.subject,
                })
            }else if(Object.keys(profileMilestones).includes(mail.sender)){
                profileMilestones[mail.sender].push({
                    date : mail.date,
                    type : "mail",
                    id : mail.id,
                    name : mail.subject,
                })
            }
        }

        let tmp_phones = Object.values(data.phones)
        for(let phone of tmp_phones){
            if(Object.keys(data.contacts).includes(phone.sender)){
                milestones.push({
                    date : phone.date,
                    type : "phone",
                    id : phone.id,
                    name : phone.subject,
                })
            }
        }

        let tmp_events = Object.values(data.events)
        for(let event of tmp_events){
            milestones.push({
                date : event.start,
                type : "event",
                id : event.id,
                name : event.name,
            })
        }

        let tmp_tasks = Object.values(data.tasks)
        for(let task of tmp_tasks){
            milestones.push({
                date : task.date,
                type : "task",
                id : task.id,
                name : task.name,
            })
        }

        let tmp_files = Object.values(data.files)
        for(let file of tmp_files){
            milestones.push({
                date : file.date,
                type : "file",
                id : file.id,
                name : file.name,
            })
        }
        
        let tmp_mailResponses = Object.values(data.mailResponses)
        for(let mailResponse of tmp_mailResponses){
            if(Object.keys(profileMilestones).includes(mailResponse.sender)){
                profileMilestones[mailResponse.sender].push({
                    date : mailResponse.date,
                    type : "mailResponse",
                    id : mailResponse.id,
                    name : "RE : " + data.mails[mailResponse.mail].subject,
                })
            }
        }

        let tmp_phoneResponses = Object.values(data.phoneResponses)
        for(let phoneResponse of tmp_phoneResponses){
            if(Object.keys(profileMilestones).includes(phoneResponse.sender)){
                profileMilestones[phoneResponse.sender].push({
                    date : phoneResponse.date,
                    type : "phoneResponse",
                    id : phoneResponse.id,
                    name : "RE : " + data.phones[phoneResponse.message].subject,
                })
            }
        }

        milestones.sort((a, b) => a.date - b.date)
        milestones.forEach(milestone => {
            milestone.date = new Date(milestone.date).toISOString().slice(0,10) + " " + new Date(milestone.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
        })

		let mainMilestonesSheet = XLSX.utils.json_to_sheet(milestones, {header: ["date","type", "name", "id"]})
        XLSX.utils.sheet_add_aoa(mainMilestonesSheet, [["Date", "Type","Nom/Sujet","ID"]], { origin: "A1" });
        mainMilestonesSheet["!cols"] = [{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }]
		XLSX.utils.book_append_sheet(timelineBook, mainMilestonesSheet, "Scénario")

        let tmp_profiles = Object.values(data.profiles)
        tmp_profiles.sort((a, b) => {
            let sortingValue = a.lastname.localeCompare(b.lastname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            if(sortingValue == 0){
                return a.firstname.localeCompare(b.firstname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            }
            return sortingValue
        })

        for(let profile of tmp_profiles){
            profileMilestones[profile.id].sort((a, b) => a.date - b.date)
            profileMilestones[profile.id].forEach(milestone => {
                milestone.date = new Date(milestone.date).toISOString().slice(0,10) + " " + new Date(milestone.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
            })

            let userMilestonesSheet = XLSX.utils.json_to_sheet(profileMilestones[profile.id], {header: ["date","type", "name", "id"]})
            XLSX.utils.sheet_add_aoa(userMilestonesSheet, [["Date", "Type","Nom/Sujet","ID"]], { origin: "A1" });
            userMilestonesSheet["!cols"] = [{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }]
            XLSX.utils.book_append_sheet(timelineBook, userMilestonesSheet, data.profiles[profile.id].lastname + " " + data.profiles[profile.id].firstname)
        }

		let timelineBuffer = XLSX.write(timelineBook, {outputType:"xlsx", type:'buffer'})
        return timelineBuffer
    },

    buildEvents(data){
        let eventsBook = XLSX.utils.book_new()

        let sheetHeaders = ["id", "name", "start", "end", "link.name", "link.url","metadata"]
        let formatedHeaders = ["ID", "Nom", "Date de debut", "Date de fin",  "Nom du lien", "URL du lien", "Métadonnées"]

        let tmp_events = []

        Object.values(data.events).forEach(event => {
            let tmp_event = {
                id : event.id,
                name : event.name,
                start : event.start,
                end : event.end,
                linkName : event.link.name,
                linkUrl : event.link.url,
                metadata : "",
            }
            if(event.metadata && event.metadata.length && event.metadata.length > 0){
                event.metadata.forEach(metadata => {
                    tmp_event.metadata += "["+metadata.key + " : " + metadata.content+"]; "
                })
            }

            tmp_events.push(tmp_event)
        })

        tmp_events.sort((a, b) => a.start - b.start)

        tmp_events.forEach(event => {
            event.start = new Date(event.start).toISOString().slice(0,10) + " " + new Date(event.start).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
            event.end = new Date(event.end).toISOString().slice(0,10) + " " + new Date(event.end).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
        })

        let eventsSheet = XLSX.utils.json_to_sheet(tmp_events, {header: sheetHeaders})
        XLSX.utils.sheet_add_aoa(eventsSheet, [formatedHeaders], { origin: "A1" });

        let colsWidth = []
        for(let i = 0; i < sheetHeaders.length; i++){
            colsWidth.push({ wch: 30 })
        }
        eventsSheet["!cols"] = colsWidth
        XLSX.utils.book_append_sheet(eventsBook, eventsSheet, "Agenda")
        
		let eventsBuffer = XLSX.write(eventsBook, {outputType:"xlsx", type:'buffer'})
        return eventsBuffer
    },

    buildTasks(data){
        let tasksBook = XLSX.utils.book_new()

        let sheetHeaders = ["id", "name", "desc", "date"]
        let formatedHeaders = ["ID", "Nom", "Description", "Date"]

        let tmp_tasks = []

        Object.values(data.tasks).forEach(task => {
            let tmp_task = {
                id : task.id,
                name : task.name,
                desc : task.desc,
                date : task.date,
            }
            tmp_tasks.push(tmp_task)
        })

        tmp_tasks.sort((a, b) => a.date - b.date)

        tmp_tasks.forEach(task => {
            task.date = new Date(task.date).toISOString().slice(0,10) + " " + new Date(task.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
        })

        let tasksSheet = XLSX.utils.json_to_sheet(tmp_tasks, {header: sheetHeaders})
        XLSX.utils.sheet_add_aoa(tasksSheet, [formatedHeaders], { origin: "A1" });

        let colsWidth = []
        for(let i = 0; i < sheetHeaders.length; i++){
            colsWidth.push({ wch: 30 })
        }
        tasksSheet["!cols"] = colsWidth
        XLSX.utils.book_append_sheet(tasksBook, tasksSheet, "Taches")
        
		let tasksBuffer = XLSX.write(tasksBook, {outputType:"xlsx", type:'buffer'})
        return tasksBuffer
    },

    buildNotes(data){
        let notesBook = XLSX.utils.book_new()

        let ordered_profiles = Object.values(data.profiles)
        ordered_profiles.sort((a, b) => {
            let sortingValue = a.lastname.localeCompare(b.lastname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            if(sortingValue == 0){
                return a.firstname.localeCompare(b.firstname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            }
            return sortingValue
        })

        let tmp_notes = []
        let sheetHeaders = ["id", "name", "comGlobal", "noteMax"]
        let formatedHeaders = ["ID", "Nom", "Commentaire global", "Note maximale"]

        for(let profile of ordered_profiles){
            sheetHeaders.push(profile.lastname+"_"+profile.firstname+"_note")
            sheetHeaders.push(profile.lastname+"_"+profile.firstname+"_message")
            formatedHeaders.push(profile.lastname+" "+profile.firstname+" note")
            formatedHeaders.push(profile.lastname+" "+profile.firstname+" commentaire")
        }

        for(let noteId in data.notes){
            let note = {
                id : noteId,
                name : data.notes[noteId].name,
                comGlobal : data.notes[noteId].comGlobal,
                noteMax : data.notes[noteId].noteMax,
            }
            for(let profile of ordered_profiles){
                note[profile.lastname+"_"+profile.firstname+"_note"] = data.notes[noteId].notes[profile.id].note
                note[profile.lastname+"_"+profile.firstname+"_message"] = data.notes[noteId].notes[profile.id].message
            }
            tmp_notes.push(note)
        }
        
        let notesSheet = XLSX.utils.json_to_sheet(tmp_notes, {header: sheetHeaders})
        XLSX.utils.sheet_add_aoa(notesSheet, [formatedHeaders], { origin: "A1" });
        
        let colsWidth = []
        for(let i = 0; i < sheetHeaders.length; i++){
            colsWidth.push({ wch: 30 })
        }
        notesSheet["!cols"] = colsWidth
        XLSX.utils.book_append_sheet(notesBook, notesSheet, "Notes")
        
		let notesBuffer = XLSX.write(notesBook, {outputType:"xlsx", type:'buffer'})
        return notesBuffer
    },

    formatFormQuestions(form){
        let outputData = ""

        outputData += "Type : Formulaire \n"
        outputData += "Titre : " + form.title + "\n"
        outputData += "ID : " + form.id + "\n\n"
        outputData += "----------------- Questions -----------------\n\n"

        let questionIndex = 0
        for(let question of form.questions){
            questionIndex++
            outputData += "Question (Q" + questionIndex + "): " + question.text + "\n"
            outputData += "Type de réponse : " + question.type + "\n"

            switch(question.type){
                case "bool":
                    outputData += "Réponse : " + (question.correctAnswer ? "Vrai" : "Faux")
                    break
                case "number":
                    outputData += "Réponse : " + question.correctAnswer
                    break
                case "text":
                    outputData += "Réponse : " + question.correctAnswer
                    break
                case "dependency":
                    outputData += "Réponse : " + question.correctAnswer
                    break
                case "singleChoice":
                    outputData += "Choix : "
                    for(let choice of question.choices){
                        outputData += choice.text + ", "
                    }
                    outputData += "\n"
                    outputData += "Réponse : " + question.correctAnswer
                    break
                case "list":
                    outputData += "Choix : "
                    for(let choice of question.choices){
                        outputData += choice.text + ", "
                    }
                    outputData += "\n"
                    outputData += "Réponse : " + question.correctAnswer
                    break
                case "multipleChoice":
                    outputData += "Choix : "
                    for(let choice of question.choices){
                        outputData += choice.text + ", "
                    }
                    outputData += "\n"
                    outputData += "Réponse : "
                    for(let answer of question.correctAnswer){
                        outputData += answer + ", "
                    }
                    break
                case "fillTheBlank":
                    outputData += "Texte à trous : "
                    for(let choice of question.choices){
                        if(choice.answer){
                            outputData += " _____ "
                        }else{
                            outputData += choice.text
                        }
                    }
                    outputData += "\n"
                    outputData += "Réponse : "
                    for(let i = 0; i < question.choices.length; i++){
                        if(question.choices[i].answer){
                            outputData += " ["+question.correctAnswer[i]+"] "
                        }else{
                            outputData += question.choices[i].text
                        }
                    }
                    break
                case "orderTheList":
                    outputData += "Choix : "
                    for(let choice of question.choices){
                        outputData += choice + ", "
                    }
                    outputData += "\n"
                    outputData += "Réponse : "
                    for(let answer of question.correctAnswer){
                        outputData += answer + ", "
                    }
                    break
                case "classification":
                    let choices = question.choices.list
                    let listTitles = [question.choices.title]

                    for(let category of question.correctAnswer){
                        choices = choices.concat(category.list)
                        listTitles.push(category.title)
                    }

                    outputData += "Choix : "
                    for(let choice of choices){
                        outputData += choice + ", "
                    }

                    outputData += "\n"
                    outputData += "Catégories : "
                    for(let listTitle of listTitles){
                        outputData += listTitle + ", "
                    }

                    outputData += "\n"
                    outputData += "Réponse : \n"
                    for(let category of question.correctAnswer){
                        outputData += "\n"
                        outputData += "   -" + category.title + " : "
                        for(let answer of category.list){
                            outputData += answer + ", "
                        }
                    }
                    break
                case "free":
                    break
                default:
                    break
            }
            outputData += "\n\n"
        }

        return outputData
    },
    
    formatFormResponse(form){
        let outputData = ""
        outputData += "Formulaire : " + form.title + "\n"
        outputData += "ID : " + form.id + "\n\n"

        for(let question of form.questions){
            outputData += "Question : " + question.text + "\n"
            outputData += "Type de réponse : " + question.type + "\n"
            outputData += "Réponse : "
            if(question.response != null && question.response != undefined){
                switch(question.type){
                    case "bool":
                        outputData += (question.response ? "Vrai" : "Faux")
                        break
                    case "number":
                        outputData += question.response
                        break
                    case "text":
                        outputData += question.response
                        break
                    case "dependency":
                        outputData += question.response
                        break
                    case "singleChoice":
                        outputData += question.response
                        break
                    case "list":
                        outputData += question.response
                        break
                    case "multipleChoice":
                        for(let response of question.response){
                            outputData += response + ", "
                        }
                        break
                    case "fillTheBlank":
                        for(let i = 0; i < question.choices.length; i++){
                            if(question.choices[i].answer){
                                outputData += " [ "
                                if(question.response[i]){
                                    outputData += question.response[i]
                                }
                                outputData += " ] "
                            }else{
                                outputData += question.choices[i].text
                            }
                        }
                        break
                    case "orderTheList":
                        for(let response of question.response){
                            outputData += response + ", "
                        }
                        break
                    case "classification":
                        for(let category of question.response){
                            outputData += "\n"
                            outputData += "   -" + category.title + " : "
                            for(let answer of category.list){
                                outputData += answer + ", "
                            }
                        }
                        break
                    case "free":
                        outputData += question.response
                        break
                    default:
                        break
                }
            }
            outputData += "NON REPONDU ! \n\n"
        }
        return outputData
    },

    async addResponseDataToZip(zip, responseData, data){        
        const mailResponsesPath = "Mails"
        for(let mailId in responseData.mails){
            let mail = data.mails[mailId]
            let mailResponses = responseData.mails[mailId]

            let mailPath = mailResponsesPath + "/" + mail.subject

            let fileContent = ""
            fileContent += "Type : Mail \n"
            fileContent += "Type de réponse : " + mail.responseType + "\n" 
            fileContent += "Id : " + mail.id + "\n"
            fileContent += "Date de publication : " + new Date(mail.date).toISOString().slice(0,10) + " " + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n"
            fileContent += "Subject : " + mail.subject + "\n"

            fileContent += "Pieces jointes : "
            if(mail.attachments && mail.attachments.length > 0 || mail.responseType == "form"){
                for(let attachment of mail.attachments){
                    fileContent += attachment.name + " "
                    
                    let fileRef = ref(storage, attachment.ref) 
                    let fileBlob = await getBlob(fileRef)

                    zip.file(mailPath + "/Pieces jointes/" + attachment.name, fileBlob)
                }
                if(mail.responseType == "form"){
                    fileContent += data.forms[mail.form].title+"(Formulaire) "
                    let formData = this.formatFormQuestions(data.forms[mail.form])
                    zip.file(mailPath + "/Pieces jointes/" + data.forms[mail.form].title+"(Formulaire).txt", formData)
                }
            }else{
                fileContent += "Aucune"
            }
            fileContent += "\n\n"
            fileContent += "----------------- CORPS DU MAIL -----------------\n\n"
            fileContent += mail.message

            zip.file(mailPath + "/Enoncé.txt", fileContent)
        }

        const phoneResponsesPath = "Répondeur"
        for(let phoneId in responseData.phones){
            let phone = data.phones[phoneId]
            let phoneResponses = responseData.phones[phoneId]

            let phonePath = phoneResponsesPath + "/" + phone.subject

            let fileContent = ""

            fileContent += "Type : Répondeur \n"
            fileContent += "Type de réponse : " + phone.responseType + "\n" 
            fileContent += "Id : " + phone.id + "\n"
            fileContent += "Date de publication : " + new Date(phone.date).toISOString().slice(0,10) + " " + new Date(phone.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n"
            fileContent += "Subject : " + phone.subject + "\n"

            let fileName = phone.subject.replace(' ', '_') + "." + phone.file.split('.').pop()

            fileContent += "Fichier audio : " + fileName + "\n"

            zip.file(phonePath + "/Enoncé.txt", fileContent)

            let fileRef = ref(storage, phone.file)
            let fileBlob = await getBlob(fileRef)

            zip.file(phonePath + "/" + fileName, fileBlob)
        }

        const responsesPath = 'Réponses'
        zip.folder(responsesPath)
        for (const userId in responseData.responses) {
            // create a folder for each user
            const user = data.profiles[userId]
            const userPath = responsesPath + '/' + user.firstname + ' ' + user.lastname
            zip.folder(userPath)

            // concat phone and mail responses
            let userResponses = {}
            for (const key in responseData.responses[userId].mails)
                userResponses[key] = responseData.responses[userId].mails[key]
            for (const key in responseData.responses[userId].phones)
                userResponses[key] = responseData.responses[userId].phones[key]

            // create a folder for each response of the user
            for (const responseId in userResponses) {
                const response = userResponses[responseId]
                let responsePath = userPath + '/' + response.subject
                // zip.folder(responsePath) // if you want folder for only 1 .txt file

                // creating file content
                let responseContent = ''
                responseContent += `Type : ${response.type === 'form' ? 'Formulaire' : response.type === 'file' ? 'Retour de Pièces jointes' : 'Mail'}\n`
                responseContent += `Id : ${response.id}\n`
                responseContent += `Date d'envoi : ${new Date(response.date).toISOString().slice(0,10)} ${new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })}\n`
                responseContent += `Emetteur : ${data.profiles[userId].lastname} ${data.profiles[userId].firstname}\n\n`
                
                if (response.type === 'file') {
                    responseContent += 'Pieces jointes : '
                    for (const attachment of response.attachments){
                        responseContent += attachment.name + ' '
                        
                        // storage of attachments
                        const fileRef = ref(storage, attachment.ref) 
                        const fileBlob = await getBlob(fileRef)
                        zip.file(responsePath + '/Pièces jointes/' + attachment.name, fileBlob)
                        responsePath += '/Mail.txt' // if you don't want folder for only 1 .txt file
                    }
                } else if (response.type == 'form') {
                    responseContent += '----------------- CORPS DU FORMULAIRE -----------------\n\n'
                    responseContent += this.formatFormResponse(response.response)
                    responsePath += '.txt' // if you don't want folder for only 1 .txt file
                } else {
                    responseContent += '----------------- CORPS DU MAIL -----------------\n\n'
                    responseContent += response.response
                    responsePath += '.txt' // if you don't want folder for only 1 .txt file
                }

                zip.file(responsePath, responseContent) // if you don't want folder for only 1 .txt file
                // zip.file(responsePath + '/Mail.txt', responseContent) // if you want folder for only 1 .txt file
            }
        }
    },

    addRoomDataToZip(zip, data){
        let ordered_profiles = Object.values(data.profiles)
        ordered_profiles.sort((a, b) => {
            let sortingValue = a.lastname.localeCompare(b.lastname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            if(sortingValue == 0){
                return a.firstname.localeCompare(b.firstname, 'fr', {ignorePunctuation: true, sensitivity: 'base'})
            }
            return sortingValue
        })

        let outputData = ""
        outputData += "Nom de la room : " + data.room.name + "\n"
        outputData += "Nombre de participants : " + ordered_profiles.length + "\n"
        outputData += "Participant : \n"

        for(let profile of ordered_profiles){
            outputData += "   - " + profile.lastname + " " + profile.firstname + " " + profile.email + " (" + profile.uid + ")\n"
        }

        zip.file("Room.txt", outputData)
    },

    async addFilesDataToZip(zip, data){
        let filesPath = "Fichiers"
        let files = data.files

        for(let fileId in files){
            let file = files[fileId]
            let filePath = file.path.split('/')
            if(filePath[0] == "home"){
                filePath.splice(0, 1)
            }
            filePath = filesPath + "/" + filePath.join('/') + (filePath.length > 0 ? "/" : "") + file.name

            if(file.type == "file"){
                let fileRef = ref(storage, file.ref)
                let fileBlob = await getBlob(fileRef)

                zip.file(filePath, fileBlob)
            }else{
                zip.folder(filePath)
            }
        }
    },
    

    addContactToZip(zip, data){
        let outputData = ""
        Object.values(data.contacts).forEach(contact => {
            outputData += "Nom : " + contact.lastname + "\n"
            outputData += "Prenom : " + contact.firstname + "\n"
            outputData += "Description : " + contact.description + "\n"
            outputData += "Email : " + contact.lastname + "." + contact.firstname + "@" + contact.domain + "\n"
            outputData += "\n\n"
        })

        zip.file("Contacts.txt", outputData)
    },

    addKeyToZip(zip, data){
        let jsonKey = {
            id: data.room.id,
            key: CryptoJS.AES.encrypt(JSON.stringify(data.room.id + "useless for now (WIP)"), CryptoKey).toString()
        }
        let key = JSON.stringify(jsonKey)

        zip.file("Key.json", key)
    },

    async archiveRoom(roomId){
        let zip = new JSZip()

        let rawData = await this.getDataFromRoom(roomId)
        let responseData = this.getResponseData(rawData)

        this.addRoomDataToZip(zip, rawData)
        this.addContactToZip(zip, rawData)
        await this.addResponseDataToZip(zip, responseData, rawData)
        await this.addFilesDataToZip(zip, rawData)

        let eventsBuffer = this.buildEvents(rawData)
        zip.file("Agenda.xlsx", eventsBuffer)

        let tasksBuffer = this.buildTasks(rawData)
        zip.file("Taches.xlsx", tasksBuffer)

        let timelineBuffer = this.buildTimeline(rawData)
        zip.file("Timeline.xlsx", timelineBuffer)

        let notesBuffer = this.buildNotes(rawData)
        zip.file("Notes.xlsx", notesBuffer)
        
        await this.addKeyToZip(zip, rawData)

        let zipBlob = await zip.generateAsync({type:"blob"})
        
        downloader.downloadBlob(zipBlob, roomId + ".zip")
    }
}

export default archiveMaker
